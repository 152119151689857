<template>
    <div class="trade">
        <div class="trade_w">
            <van-list
                class="trade_list"
                v-model="loading"
                offset="50"
                :finished="finished"
                finished-text="没有更多了"
                :error.sync="error"
                error-text="请求失败，点击重新加载"
                @load="onLoad"
            >
                <van-cell v-for="item in emptionList" :key="item.id" >
                    <div @click="listClick($event, item)">
                        <div class="trade_list_w">
                            <img class="profilePhoto" :src="item.imgSrc">
                            <span class="index_list_c">
                                <div class="index_list_c_t" v-if="item.onsale_type == 1">
                                    {{item.channel_name}}\{{item.job}}\{{item.lv}}级
                                    <span>{{item.server_name}}</span>
                                </div>
                                <div class="index_list_c_t" v-else-if="item.onsale_type == 3">{{item.channel_name}}\{{item.item_name}}</div>
                                <div class="index_list_c_t" v-else>{{item.channel_name}}\{{item.item_name}}×{{item.item_num}}万</div>
                                <div class="index_list_c_c">
                                    ￥{{item.price}}
                                </div>
                                <div class="index_list_c_b">
                                    <div class="index_list_c_b_l">
                                        <span class="index_list_c_b_b">购买时间：{{item.surplus_time}}</span>
                                    </div>
                                    <div class="index_list_c_b_r">
                                        <van-button plain round type="info" size="mini" color="#707070" @click="delClick($event, item)">删除记录</van-button>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>
                </van-cell>
            </van-list>
            <div class="pop_wrap">
                <van-popup v-model="delShow" position="top">
                    <div class="pop_w">
                        <p>是否确认删除订单？</p>
                    </div>
                    <div class="pop_btns">
                        <van-button round type="info" size="small" color="linear-gradient(to right, #FF704D, #F73727)" @click="delConfirm">确认</van-button>
                        <van-button plain round type="info" size="default" @click="delShow=false;">取消</van-button>
                    </div>
                </van-popup>
            </div>
        </div>
    </div>
</template>
<script>
 import Top from '@/components/top.vue'

export default {
    name: 'Emptiontrade',
    components: {
        Top,
    },
    data() {
        return {
            loading: false,
            finished: false,
            error: false,
            emptionList: [],
            delShow: false,
            total: 0,
            page: 1,
            goodsId: '',
            order_sn:''
        }
    },
    created(){
    //   this.onLoad()
    },
    methods: {
        //列表加载
        onLoad() {
            // 异步更新数据 
            this.$api.account.trade({
                status: 6,
                page: this.page
            }).then(res => {
                if (res.code == 200) {
                    // this.emptionList=[]
                    if (res.data.data.length > 0) {
                        res.data.data.forEach((item) => {
                            this.emptionList.push(item);
                        });
                        this.total = res.data.total;
                        //加载状态结束
                        this.loading = false;
                        //数据全部加载完成
                        if (this.page * 8  >= this.total) {
                            this.finished = true;
                        }
                        this.page ++;
                        //关掉错误提示
                        this.error = false;
                        this.emptionList.forEach((item) => {
                            if (item.onsale_type != 1) {
                                var imgSrc = '';
                                try{
                                    imgSrc = require('../../assets/images/itemicon/' + item.channel_id + '/' + item.icon_id + '.png');
                                }catch(e){
                                    imgSrc = require('../../assets/images/question.png');
                                }
                                this.$set(item, 'imgSrc', imgSrc);
                                return false;
                            }
                            //获取头像
                            if (item.sex == 200) {
                                switch(item.job) {
                                    case '战士':
                                        item.imgSrc = require('../../assets/images/photos/zs-nan@2x.png');
                                        break;
                                    case '法师':
                                        item.imgSrc = require('../../assets/images/photos/fa-nan@2x.png');
                                        break;
                                    default:
                                        item.imgSrc = require('../../assets/images/photos/ds-nan@2x.png');
                                }
                            }else{
                                switch(item.job) {
                                    case '战士':
                                        item.imgSrc = require('../../assets/images/photos/zs-nv@2x.png');
                                        break;
                                    case '法师':
                                        item.imgSrc = require('../../assets/images/photos/fa-nv@2x.png');
                                        break;
                                    default:
                                        item.imgSrc = require('../../assets/images/photos/ds-nv@2x.png');
                                }
                            }
                        });
                    }else{
                        //加载状态结束
                        this.loading = false;
                        //数据全部加载完成
                        this.finished = true;
                    }
                }else{
                    //加载状态结束
                    this.loading = false;
                    //错误提示
                    this.error = true;
                }
            }).catch(err => {
                console.log(err);
            });
        },
        //删除订单
        delClick(e, item) {
            this.goodsId = item.goods_id;
            this.order_sn=item.order_sn;
            //阻止事件的默认行为
            //e.preventDefault();
            this.delShow = true;
        },
        //确认删除订单
        delConfirm() {
            this.delShow = false;
            this.$api.account.goodsOperate({
                goods_id: this.goodsId,
                order_sn:this.order_sn,
                status: 6
            }).then(res => {
                if (res.code == 200) {
                    this.$toast({
                        message: res.data,
                        duration: 1500
                    });
                    this.emptionList = [];
                    this.page = 1;
                    this.finished = false;
                    this.loading = true;
                    this.onLoad();
                }
            }).catch(err => {
                console.log(err);
            });
        },
        //点击跳转到商品详情
        listClick(e, item) {
            if (e.target.nodeName != 'BUTTON') {
            if (item.onsale_type==2 || item.onsale_type==4) {  
            }else{
                this.$router.push({
                    name: 'characterDetails',
                    query: {
                        channel_id: item.channel_id,
                        goods_id: item.goods_id,
                        is_from: 'sold'
                    }
                });
            
            }
            }
        },
    }
}
</script>

